import React from "react";
import { Link } from "react-router-dom";

const PartnerLink = ({ partner }) => {
  return (
    <Link to={"/partners/" + partner?.id} style={{ borderBottom: "1px dotted" }}>
      {partner?.first_name || partner?.last_name
        ? `${partner?.first_name} ${partner?.last_name}`
        : partner?.company_name}
    </Link>
  );
};

export default PartnerLink;
