import { CheckOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, notification, Row, Space } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { InvoiceContext } from "../../pages/InvoiceProfile";
import LineItemRemoveButton from "./LineItemRemoveButton";

const size = "normal";

const LineItem = ({ idInvoice, lineItem, readOnly, locked, resetAddingNewItem, items, invoiceType }) => {
  const { t } = useTranslation();

  const { refreshInvoice } = useContext(InvoiceContext);

  const [isReadOnly, setReadOnly] = useState(readOnly);
  const [isSubmitting, setSubmitting] = useState(false);

  const [form] = Form.useForm();

  const handleTotal = (changedValues, allValues) => {
    let quantity = allValues.quantity || 1;
    let vatPercentage = allValues.vat_percentage || 0;
    let totalAmount = allValues.total_amount || 0;
    let listPrice = totalAmount / (1 + vatPercentage);
    let vatAmount = totalAmount - totalAmount / (1 + vatPercentage);

    if (allValues.total_amount) {
      form.setFieldsValue({
        list_price: listPrice,
        quantity: quantity,
        vat_percentage: vatPercentage,
        vat_amount: vatAmount,
        total_amount: totalAmount,
      });
    }
  };

  return (
    <Form
      name={lineItem?.id}
      form={form}
      onFinish={(values) => {
        setSubmitting(true);
        if (lineItem && lineItem.hasOwnProperty("id")) {
          coreApi
            .put("/line-items/" + lineItem?.id, values)
            .then((response) => {
              notification.success({
                description: response.data.message,
              });
              refreshInvoice(response.data.invoice);
              resetAddingNewItem();
            })
            .catch((error) => {
              console.log(error.response);
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          coreApi
            .post("/line-items", values)
            .then((response) => {
              notification.success({
                description: response.data.message,
              });
              refreshInvoice(response.data.invoice);
              resetAddingNewItem();
            })
            .catch((error) => {
              console.log(error.response);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
        setReadOnly(true);
        setSubmitting(false);
      }}
      onValuesChange={handleTotal}
    >
      <Row gutter={[8, 0]}>
        <Col span={8}>
          <Form.Item hidden name="invoice_id" initialValue={idInvoice}>
            <Input></Input>
          </Form.Item>
          <Form.Item hidden name="line_item_id" initialValue={lineItem?.line_item_id}>
            <Input></Input>
          </Form.Item>
          <Form.Item hidden name="quantity" initialValue={1}>
            <Input></Input>
          </Form.Item>

          <Form.Item
            initialValue={lineItem?.content}
            name="content"
            rules={[
              {
                required: true,
                message: t("finance.lineItems.validation.content"),
              },
            ]}
          >
            <Input.TextArea autoSize size={size} disabled={isReadOnly} style={{ width: "100%" }}></Input.TextArea>
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item
            initialValue={lineItem?.list_price}
            name="list_price"
            rules={[
              {
                required: true,
                message: t("finance.lineItems.validation.listPrice"),
              },
            ]}
            hidden={invoiceType === 2 ? false : true}
          >
            <InputNumber precision={2} size={size} disabled={isReadOnly} style={{ width: "100%" }}></InputNumber>
          </Form.Item>
        </Col>

        {invoiceType === 2 && (
          <>
            <Col span={3}>
              <Form.Item initialValue={lineItem?.discount_amount || 0} name="discount_amount">
                <InputNumber min={0} size={size} disabled={isReadOnly} style={{ width: "100%" }}></InputNumber>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                initialValue={Number(lineItem?.vat_percentage) || 0.21}
                name="vat_percentage"
                rules={[
                  {
                    type: "number",
                    min: 0,
                    max: 1,
                    message: t("finance.lineItems.validation.vatPercentage"),
                  },
                ]}
              >
                <InputNumber
                  size={size}
                  decimalSeparator={"."}
                  step={0.01}
                  formatter={(value) => `${value * 100}%`}
                  parser={(value) => {
                    let valueParsed = value.replace("%", "") / 100;
                    return valueParsed;
                  }}
                  disabled={isReadOnly}
                  style={{ width: "100%" }}
                ></InputNumber>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                initialValue={lineItem?.vat_amount}
                name="vat_amount"
                rules={[
                  {
                    required: true,
                    message: t("finance.lineItems.validation.vatAmount"),
                  },
                ]}
              >
                <InputNumber precision={2} size={size} disabled={isReadOnly} style={{ width: "100%" }}></InputNumber>
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={3}>
          <Form.Item initialValue={lineItem?.total_amount} name="total_amount">
            <InputNumber precision={2} size={size} disabled={isReadOnly} style={{ width: "100%" }}></InputNumber>
          </Form.Item>
        </Col>
        <Col span={2}>
          {isReadOnly ? (
            <Space>
              <Button
                disabled={locked}
                size={size}
                onClick={() => {
                  setReadOnly(false);
                }}
                icon={<EditOutlined />}
              ></Button>
              <LineItemRemoveButton
                resetAddingNewItem={resetAddingNewItem}
                lineItem={lineItem}
                locked={locked}
                size={size}
              />
            </Space>
          ) : (
            <Space>
              <Button
                size={size}
                loading={isSubmitting}
                onClick={() => {
                  form.submit();
                }}
                icon={<CheckOutlined />}
              ></Button>

              <Button
                size={size}
                onClick={() => {
                  form.resetFields();
                  setReadOnly(true);
                }}
                icon={<StopOutlined />}
              ></Button>
            </Space>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default LineItem;
