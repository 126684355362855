import { UserOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";

const buttonStyle = {
  width: "100%",
};

const UserOptionsPopup = ({ linkClickHandler }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Row gutter={[8, 24]} style={{ marginBottom: "12px" }}>
        <Col span={24}>
          <Link to={"/profile"} onClick={linkClickHandler}>
            <Button block type="normal" size="small" style={buttonStyle}>
              {t("common.profile")}
            </Button>
          </Link>
        </Col>
      </Row>
      <Row gutter={[8, 24]}>
        <Col span={24}>
          <Link
            to={"/login"}
            onClick={() => {
              coreApi
                .post("/auth/logout", {
                  token_id: localStorage.getItem("tokenId"),
                })
                .then((response) => {
                  if (response.status === 200) {
                    linkClickHandler();
                    localStorage.removeitem("tokenId");
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("user");
                  } else {
                    console.log("Failed.");
                  }
                })
                .catch((error) => console.log(error));
            }}
          >
            <Button block type="danger" size="small" style={buttonStyle}>
              {t("common.logout")}
            </Button>
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const HeaderBarUserPopper = () => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Popover
      placement="bottomRight"
      title={user ? user.username : ""}
      content={
        <UserOptionsPopup
          linkClickHandler={() => {
            setPopoverVisible(false);
          }}
        ></UserOptionsPopup>
      }
      onVisibleChange={() => {
        setPopoverVisible(false);
      }}
      visible={isPopoverVisible}
    >
      <Button
        onMouseEnter={() => {
          setPopoverVisible(true);
        }}
        icon={<UserOutlined />}
      />
    </Popover>
  );
};

export default HeaderBarUserPopper;
