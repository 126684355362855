import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EyeTwoTone } from "@ant-design/icons";
import { useParams } from "react-router";
import { coreApi } from "../api/calls";
import parse from "html-react-parser";
import moment from "moment/moment";

/**
 * Page with all sent emails
 * @component
 * @alias CommunicationPane
 * @returns <React.Fragment /> with content
 */
const CommunicationPane = () => {
  // Translation
  const { t } = useTranslation();

  // Id from URL
  const { id } = useParams();

  // States
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState({});
  const [pagination, setPagination] = useState({});
  const [params, setParams] = useState({});
  const [loading, setDataLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  /**
   * Handle street onchange filter with lodash
   */

  useEffect(() => {
    setDataLoading(true);

    coreApi
      .get(`/emails/buildings/` + id, { params: params })
      .then((response) => {
        let { data, ...pagination } = response.data;

        setEmails(data);
        setPagination(pagination);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false)
      });
  }, [params]);

  // Columns of table
  const columns = [
    {
      title: t("emails.date"),
      key: "created_at",
      dataIndex: "created_at",
      width: "15%",
      render: (rowData) => {
        return moment(rowData).format("DD") + "." + moment(rowData).format("MM") + "." + moment(rowData).format("YYYY");
      },
    },
    {
      title: t("emails.type"),
      key: "type",
      dataIndex: "type",
      width: "15%",
      render: (rowData) => {
        return t("emails.types." + rowData);
      },
    },
    {
      title: t("emails.to"),
      key: "to",
      dataIndex: "to",
      width: "30%",
    },
    {
      title: t("emails.subject"),
      key: "subject",
      dataIndex: "subject",
      widt: "40%",
    },
    {
      title: t("emails.content"),
      key: "content",
      width: "10%",
      render: (email) => {
        return (
          <EyeTwoTone
            style={{ fontSize: "24px" }}
            onClick={() => {
              setEmail(email);
              setIsModalVisible(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
                <Table
                  size={"small"}
                  loading={loading}
                  dataSource={emails}
                  pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    onChange: (e, perPage) => {
                      setParams({ ...params, page: e, perPage: perPage });
                    },
                    showTotal: (total, range) => (
                      <p>
                        {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                        {" " + t("common.paginationPartTwo") + " "}
                        <strong>{total}</strong> {t("common.paginationPartThree")}
                      </p>
                    ),
                  }}
                  columns={columns}
                  onChange={(e) => {
                    console.log(e);
                  }}
                />


              <Modal
                width={1000}
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
              >
                {email.body_html && parse(email?.body_html)}
              </Modal>
    </React.Fragment>
  );
};

export default CommunicationPane;
