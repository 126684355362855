import React from "react";
import { Link } from "react-router-dom";

/**
 * @component
 * @alias EmployeeLink
 * @property {object} employee
 * @returns <Link /> with employees name and hrefs to his profile
 */
const EmployeeLink = ({ employee }) => {
  return (
    <Link to={"/employees/" + employee.id} style={{ borderBottom: "1px dotted" }}>
      {employee.first_name + " " + employee.last_name}
    </Link>
  );
};

export default EmployeeLink;
