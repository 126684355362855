import React, { useEffect, useState, useCallback } from "react";
import { Table, Tooltip, Tag, Space, Button, Modal, notification, Select, Popconfirm, Input, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined, DeleteOutlined, SaveOutlined, CloseOutlined, FilePdfOutlined } from "@ant-design/icons";
import BuildingUnitSetupChangesAddForm from "../components/Buildings/BuildingUnitSetupChangesAddForm";
import { coreApi } from "../api/calls";
import EmployeeLink from "../components/Employees/EmployeeLink";
import BuildingUnitLink from "../components/Buildings/BuildingUnitLink";
import fileDownload from "js-file-download";
import LanguageSelect from '../components/LanguageSelect';
import PartnerLink from "../components/Partners/PartnerLink";
import PartnerSelect from "../components/Partners/PartnerSelect";
import RruleDaySelect from "../components/Scheduler/RruleDaySelect";
import { rrulestr } from "rrule";

const { Option } = Select;

const BuildingUnitSetupChangesIndex = () => {
  const { t, i18n } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [sorter, setSorter] = useState({});
  const [managers, setManagers] = useState([]);
  const [actions, setActions] = useState([]);
  const [filters, setFilters] = useState({
    manager: [user.employee_id],
  });

  const [productType, setProductType] = useState("apartment_buildings");
  const [displayPrice, setDisplayPrice] = useState("total");
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || "cs");

  useEffect(() => {
    fetchBuildingUnitSetupChanges(pagination, sorter, filters);
    fetchSetupChangesStatuses();
    fetchManagers();
    fetchActions();
  }, []);

  const [editingKey, setEditingKey] = useState('');
  const [editingValue, setEditingValue] = useState('');

  const fetchBuildingUnitSetupChanges = useCallback((paginationParams = {}, sorterParams = {}, filtersParams = {}) => {
    setLoading(true);
    const { current, pageSize } = paginationParams;
    const { order, field } = sorterParams;

    const filterParams = Object.keys(filtersParams).reduce((acc, key) => {
      acc[key] = filtersParams[key]?.join(",") || null;
      return acc;
    }, {});

    filterParams.status_id = filterParams.status_id || selectedStatus;

    coreApi
      .get("/building-unit-setup-changes", {
        params: {
          page: current,
          per_page: pageSize,
          order: order === "ascend" ? "asc" : "desc",
          sort_by: field,
          ...filterParams,
        },
      })
      .then((response) => {
        const rowData = calculateRowSpan(response.data.data);
        setData(rowData);
        setPagination({
          current: response.data.current_page,
          pageSize: response.data.per_page,
          total: response.data.total,
        });
        setLoading(false);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") + (error.response?.data?.message || "") });
        setLoading(false);
      });
  }, [selectedStatus, t]);

  const fetchSetupChangesStatuses = async () => {
    coreApi
      .get("/building-unit-setup-changes/statuses")
      .then((response) => {
        setStatuses(response.data);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") + (error.response?.data?.message || "") });
      });
  };
  const fetchManagers = async () => {
    coreApi
      .get("/employees/all")
      .then((response) => {
        const sortedManagers = response.data.sort((a, b) => {
          if (a.id === user.employee_id) return -1;
          if (b.id === user.employee_id) return 1;
          if (a.user?.deleted_at === null && b.user?.deleted_at !== null) return -1;
          if (a.user?.deleted_at !== null && b.user?.deleted_at === null) return 1;

          const firstNameComparison = a.first_name.localeCompare(b.first_name);
          if (firstNameComparison !== 0) return firstNameComparison;
          return a.last_name.localeCompare(b.last_name);
        });
        setManagers(sortedManagers);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") + (error.response?.data?.message || "") });
      });
  };

  const fetchActions = async () => {
    coreApi
      .get("/building-unit-setup-changes/actions")
      .then((response) => {
        setActions(response.data);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") + (error.response?.data?.message || "") });
      });
  };

  const calculateRowSpan = (data) => {
    const groupingCounts = data.reduce((acc, item) => {
      const key = item.grouping_param;
      if (!acc[key]) {
        acc[key] = { count: 0, index: Object.keys(acc).length + 1 };
      }
      acc[key].count++;
      return acc;
    }, {});

    return data.map((item, index, arr) => {
      const isRowSpanStart = index === 0 || item.grouping_param !== arr[index - 1].grouping_param;
      return {
        ...item,
        rowSpan: isRowSpanStart ? groupingCounts[item.grouping_param].count : 0,
        rowSpanIndex: isRowSpanStart ? groupingCounts[item.grouping_param].index : null,
        rowClass: isRowSpanStart ? "grouped-row" : "",
      };
    });
  };

  const handleSelectChange = (value) => {
    setSelectedStatus(value);
    coreApi
      .put("/building-unit-setup-changes/update-status", {
        ids: selectedRowKeys,
        status: value,
      })
      .then((response) => {
        notification.success({ message: t("buildingSetupChanges.messages.statusUpdated") });
        setSelectedRowKeys([]);
        fetchBuildingUnitSetupChanges(pagination, sorter);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedUpdateStatus") + (error.response?.data?.message || "") });
      });
  };

  const handleDelete = (id) => {
    coreApi
      .delete(`/building-unit-setup-changes/${id}`)
      .then((response) => {
        notification.success({ message: t("common.recordDeleted") });
        fetchBuildingUnitSetupChanges(pagination, sorter, filters);
      })
      .catch((error) => {
        notification.error({ message: t("common.deletionFailed") + (error.response?.data?.message || "") });
      });
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    setPagination(pagination);
    setSorter(sorter);
    const updatedFilters = { ...filters, ...tableFilters };
    setFilters(updatedFilters);
    fetchBuildingUnitSetupChanges(pagination, sorter, updatedFilters);
  };

  const handleDownloadPdf = () => {
    if (!productType || displayPrice === null) {
      notification.error({ message: t("buildingSetupChanges.messages.selectOptions") });
      return;
    }

    coreApi
      .post(
        "/building-unit-setup-changes/download/pdf",
        { ids: selectedRowKeys, productType, displayPrice, language: selectedLanguage },
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, "Nabídka_zakázek.pdf");
        setIsDownloadModalVisible(false);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedDownloadPdf") + (error.response?.data?.message || "")});
      });
  };

  const handleEdit = (record) => {
    setEditingKey(record.id);
    setEditingValue(record.notes);
  };

  const handleSave = (id) => {
    coreApi
      .put(`/building-unit-setup-changes/${id}/update-notes`, { notes: editingValue })
      .then((response) => {
        notification.success({ message: t("common.saveSuccess") });
        setEditingKey('');
        setEditingValue('');
        fetchBuildingUnitSetupChanges(pagination, sorter, filters);
      })
      .catch((error) => {
        notification.error({ message: t("common.saveFailed") + (error.response?.data?.message || "") });
      });
  };

  const handleCancel = () => {
    setEditingKey('');
    setEditingValue('');
  };

  const formatRruleDays = (rrule) => {
    const daysMap = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
    const daysNames = {
      MO: t("rrules.byweekday.MO"),
      TU: t("rrules.byweekday.TU"),
      WE: t("rrules.byweekday.WE"),
      TH: t("rrules.byweekday.TH"),
      FR: t("rrules.byweekday.FR"),
      SA: t("rrules.byweekday.SA"),
      SU: t("rrules.byweekday.SU"),
    };
    const selectedDays = rrule ? rrulestr(rrule).origOptions.byweekday.map((weekday) => daysMap[weekday.weekday]) : [];
    return selectedDays.map(day => daysNames[day]).join(", ");
  };

  const handleRruleChange = (rruleString, id) => {
    const newData = data.map(item => {
      if (item.id === id) {
        return { ...item, substitute_rrule: rruleString };
      }
      return item;
    });
    setData(newData);

    // Save the updated rrule to the backend
    coreApi
      .put(`/building-unit-setup-changes/${id}/update-rrule`, { substitute_rrule: rruleString })
      .then((response) => {
        notification.success({ message: t("common.saveSuccess") });
      })
      .catch((error) => {
        notification.error({ message: t("common.saveFailed") + (error.response?.data?.message || "") });
      });
  };

  const handleSubstitutePartnerChange = (partner, recordId) => {
    coreApi
      .put(`/building-unit-setup-changes/${recordId}/update-substitute-partner`, { substitute_partner_id: partner.id })
      .then((response) => {
        notification.success({ message: t("common.saveSuccess") });
        fetchBuildingUnitSetupChanges(pagination, sorter, filters);
      })
      .catch((error) => {
        notification.error({ message: t("common.saveFailed") + (error.response?.data?.message || "") });
      });
  };

  useEffect(() => {
    fetchSetupChangesStatuses();
    fetchManagers();
    fetchActions();
    fetchBuildingUnitSetupChanges(pagination, sorter, filters);
  }, []);

  const columns = [
    {
      title: t("schedules.status"),
      key: "status_id",
      dataIndex: "status_id",
      sorter: true,
      width: 105,
      fixed: 'left',
      render: (text, record) =>
        record.status_id === 1 ? (
          <Tag color="orange">{t("buildingSetupChanges.statusInSolution")}</Tag>
        ) : (
          <Tag color="green">{t("buildingSetupChanges.statusSolved")}</Tag>
        ),
      filters: statuses.map(status => ({
        text: status.name,
        value: status.id,
      })),
      onFilter: (value, record) => record.status_id === value,
    },
    {
      title: t("expansion.managerName"),
      key: "manager",
      sorter: true,
      width: 150,
      fixed: 'left',
      filters: managers.map(manager => ({
        text: (
          <span>
            {manager?.user?.deleted_at === null ? (
              `${manager.first_name} ${manager.last_name}`
            ) : (
              <s>{`${manager.first_name} ${manager.last_name}`}</s>
            )}
          </span>
        ),
        value: manager.id,
      })),
      filteredValue: filters.manager,
      onFilter: (value, record) => record.manager.id === value,
      render: (text, record) => <EmployeeLink employee={record.manager} />,
    },
    {
      title: t("common.address"),
      sorter: true,
      key: "address",
      width: 220,
      fixed: 'left',
      render: (text, record) => <BuildingUnitLink buildingUnit={record.building_unit} />,
    },
    {
      title: t("common.city"),
      key: "city",
      width: 150,
      fixed: 'left',
      sorter: (a, b) => a.city_branch.name.localeCompare(b.city_branch.name),
      render: (text, record) => record.city_branch.name,
    },
    {
      title: t("buildingSetupChanges.action"),
      key: "action_id",
      dataIndex: "action_id",
      sorter: true,
      width: 195,
      fixed: 'left',
      filters: actions.map(action => ({
        text: action.translations.find(translation => translation.language_ietf === "cs").tag_name,
        value: action.id,
      })),
      onFilter: (value, record) => record.action_id === value,
      render: (text, record) => {
        const actionTranslation = record.action?.translations.find((translation) => translation.language_ietf === "cs");
        return <>{actionTranslation ? actionTranslation.tag_name : text}</>;
      },
    },
    {
      title: t("buildingSetupChanges.actionWeek"),
      key: "action_week",
      width: 120,
      render: (text, record) => {
        const formatDate = (date) => {
          const d = new Date(date);
          return `${String(d.getDate()).padStart(2, '0')}-${String(d.getMonth() + 1).padStart(2, '0')}`;
        };
        return record.action_week_to ? `${formatDate(record.action_week_from)} - ${formatDate(record.action_week_to)}` : `${formatDate(record.action_week_from)}`;
      },
    },
    {
      title: (
        <span>
          {t("buildingSetupChanges.partnerReward")}{" "}
          <Tooltip title="(activeOrderPrice * 0.6) / buildingUnitsCount">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      key: "partner_reward",
      width: 100,
      render: (text, record) => {
        if (record?.active_order && record.active_order[0] && record.building && record.building.building_units) {
          const totalAmount = parseFloat(record.active_order[0].price);
          const buildingUnits = record.building.building_units;
          const partnerReward = (totalAmount * 0.6) / buildingUnits.length;

          const res = partnerReward.toFixed(2);
          const tooltipTitle = `(${totalAmount} * 0.6) / ${buildingUnits.length}`;

          return (
            <span>
              {res}{" "}
              <Tooltip title={tooltipTitle}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: t("common.district"),
      key: "district",
      dataIndex: "building.district",
      width: 150,
      render: (text, record) => {
        return <div>{record.building.district}</div>;
      },
    },
    {
      title: t("buildingSetupChanges.cleaningFrequency"),
      key: "building_unit.service_rrules.sort_weight",
      dataIndex: "building_unit",
      sorter: true,
      width: 130,
      render: (building_unit) => {
        const sortedRrules = (building_unit?.service_rrules || [])
          .filter((rrule) => rrule.rrule?.includes("WEEKLY"))
          .sort((a, b) => {
            return (a.cleaning_service_group?.sort_weight || 0) - (b.cleaning_service_group?.sort_weight || 0);
          });

        if (sortedRrules.length > 0) {
          return sortedRrules[0] ? sortedRrules[0].cleaning_service_group.name : "-";
        }

        return "-";
      },
    },
    {
      title: t("buildingSetupChanges.floorCount"),
      key: "floor_count",
      width: 120,
      render: (text, record) =>
        `${record.building_unit.floor_count}, ${record.building_unit.ground_floor_count}, ${record.building_unit.basement_floor_count}`,
    },
    {
      title: t("common.notes"),
      key: "notes",
      width: 250,
      render: (text, record) => {
        if (editingKey === record.id) {
          return (
            <div>
              <Input
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                style={{ width: "calc(100% - 60px)", marginRight: 8 }}
              />
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => handleSave(record.id)}
                style={{ marginRight: 8 }}
              />
              <Button
                icon={<CloseOutlined />}
                onClick={handleCancel}
              />
            </div>
          );
        } else {
          return (
            <Tooltip title={record.notes}>
              <div
                className="editable-cell"
                onClick={() => handleEdit(record)}
              >
                {record.notes || "-"}
              </div>
            </Tooltip>
          );
        }
      }
    },
    {
      title: t("common.partner"),
      key: "partner",
      dataIndex: "partner",
      width: 150,
      render: (text, record) => {
        return <PartnerLink partner={record.partner || record.active_order[0].client} />;
      },
    },
    {
      title: t("common.day"),
      key: "day",
      dataIndex: "day",
      width: 120,
      render: (text, record) => {
        return record.rrule ? (
          <Tooltip title={formatRruleDays(record.rrule, t)}>
            {formatRruleDays(record.rrule, t)}
          </Tooltip>
        ) : (
          <Tooltip title={record.day}>
            <span>{record.day}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("common.substitutePartner"),
      key: "substitute_partner",
      dataIndex: "substitute_partner",
      width: 180,
      render: (text, record) => {
        if (!record.substitute_partner && record.status_id === 1) {
          return (
            <PartnerSelect
              name={"substitute_partner_id"}
              onChange={(value) => handleSubstitutePartnerChange(value, record.id)}
            />
          );
        } else {
          return record.substitute_partner ? <PartnerLink partner={record.substitute_partner} /> : <span></span>;
        }
      },
    },
    {
      title: t("common.substituteDay"),
      key: "new_day",
      dataIndex: "new_day",
      width: 140,
      render: (text, record) => {
        const rrule = record.substitute_rrule || record.rrule;
        const isEditable = !record.substitute_rrule && record.status_id === 1;

        return isEditable ? (
          <RruleDaySelect
            rrule={rrule}
            postBlur={(rruleString) => handleRruleChange(rruleString, record.id)}
            showLabel={false}
          />
        ) : (
          <Tooltip title={formatRruleDays(rrule, t)}>
            <span>{formatRruleDays(rrule, t) || record.new_day}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("common.actions"),
      key: "actions",
      width: 70,
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title={t("common.confirmDelete")}
            onConfirm={() => handleDelete(record.id)}
            okText={t("common.confirmAnswer")}
            cancelText={t("common.rejectAnswer")}
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const showDownloadModal = () => {
    setIsDownloadModalVisible(true);
  };

  const hideDownloadModal = () => {
    setIsDownloadModalVisible(false);
  };

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={showModal}>
          {t("buildings.add")}
        </Button>
        <Button
          type="primary"
          icon={<FilePdfOutlined />}
          disabled={selectedRowKeys.length === 0}
          onClick={showDownloadModal}
        >
          {t("buildingSetupChanges.downloadPdf")}
        </Button>
      </Space>
      <Select
        placeholder={t("buildingSetupChanges.selectStatus")}
        onChange={handleSelectChange}
        value={selectedStatus}
        disabled={selectedRowKeys.length === 0}
        style={{ marginBottom: 16, float: "right", width: 200 }}
        size="large"
      >
        {statuses.map((status) => (
          <Option key={status.id} value={status.id}>
            {status.name}
          </Option>
        ))}
      </Select>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        loading={loading}
        bordered={true}
        rowKey="id"
        scroll={{ x: 1500 }}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        rowClassName={(record, index) => record.rowClass}
      />

      <Modal title={t("buildings.add")} open={isModalVisible} onCancel={hideModal} footer={null}>
        {isModalVisible && (
          <BuildingUnitSetupChangesAddForm
            onFormSubmit={() => {
              hideModal();
              fetchBuildingUnitSetupChanges(pagination, sorter, filters);
            }}
          />
        )}
      </Modal>

      <Modal
        title={t("buildingSetupChanges.downloadPdf")}
        open={isDownloadModalVisible}
        onCancel={hideDownloadModal}
        onOk={handleDownloadPdf}
      >
        <div>
          <h4>{t("buildingSetupChanges.selectProductType")}</h4>
          <Radio.Group
            className="vertical-radio-group"
            onChange={(e) => setProductType(e.target.value)}
            value={productType}
          >
            <Radio value="apartment_buildings">
              {t("buildingSetupChanges.offerForApartmentBuildings")}
            </Radio>
            <Radio value="commercial_spaces" disabled>
              {t("buildingSetupChanges.offerForCommercialSpaces")}
            </Radio>
          </Radio.Group>
        </div>
        <div style={{ marginTop: 16 }}>
          <h4>{t("buildingSetupChanges.selectRewardDisplay")}</h4>
          <Radio.Group
            className="vertical-radio-group"
            onChange={(e) => setDisplayPrice(e.target.value)}
            value={displayPrice}
          >
            <Radio value="total">
              {t("buildingSetupChanges.createPdfWithTotalReward")}
            </Radio>
            <Radio value="separate">
              {t("buildingSetupChanges.createPdfWithSeparateReward")}
            </Radio>
            <Radio value="none">
              {t("buildingSetupChanges.createPdfWithoutReward")}
            </Radio>
          </Radio.Group>
        </div>
        <div style={{ marginTop: 16 }}>
          <LanguageSelect
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default BuildingUnitSetupChangesIndex;
