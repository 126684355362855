import { Form, notification, Select } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const BuildingUnitSelect = ({
  label,
  name,
  initialValue = null,
  disabledOption,
  readOnly,
  required,
  validationMessage,
  size,
  onChange = null,
  buildingId = null,
  partnerId = null,
  cityBranchId = null,
  mode = "single",
  selectAllOnLoad = false,
  form = null
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [buildingUnits, setBuildingUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState(initialValue || []);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    const params = {
      building_id: buildingId,
      partner_id: partnerId,
      city_branch_id: cityBranchId
    };

    setLoading(true);
    coreApi
      .get("/buildings/units/all", { params })
      .then((response) => {
        if (isMounted.current) {
          setBuildingUnits(response.data);
          if (selectAllOnLoad && form && (partnerId || buildingId)) {
            const allIds = response.data.map(unit => unit.id);
            setSelectedUnits(allIds);
            form.setFields([{ name, value: allIds }]);
          }
        }
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    return () => {
      isMounted.current = false;
    };
  }, [buildingId, partnerId, cityBranchId]);

  const handleChange = (value) => {
    setSelectedUnits(value);
    onChange && onChange(value);
  };

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        size={size}
        allowClear
        disabled={readOnly}
        loading={loading}
        showSearch={true}
        mode={mode}
        placeholder={t("buildings.selectUnit")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={handleChange}
        value={selectedUnits}
      >
        {buildingUnits.map((buildingUnit) => {
          return (
            <Select.Option
              key={buildingUnit?.id}
              value={buildingUnit?.id}
              disabled={disabledOption && buildingUnit?.id === disabledOption}
            >
              {buildingUnit?.street + " " + buildingUnit?.house_number}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default BuildingUnitSelect;
