import { Col, DatePicker, Form, InputNumber, Modal, notification, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RRule, rrulestr } from "rrule";
import { coreApi } from "../../api/calls";
import PartnerSelect from "../Partners/PartnerSelect";
import RruleDaySelect from "./RruleDaySelect";
import BiweeklyTypeSelect from "./BiweeklyTypeSelect";

const ScheduleMassEditForm = ({ isOpen, close, schedule, refreshScheduleList, buildingUnitId }) => {
  const { t } = useTranslation();

  const [scheduleEditForm] = Form.useForm();
  const [isBiweeklyRequired, setIsBiweeklyRequired] = useState(false);
  const [biweeklyType, setBiweeklyType] = useState(null);

  // const [selectedDaysCount, setSelectedDaysCount] = useState(0);
  // const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    scheduleEditForm.resetFields();
    let isBiweekly = false;
    let newBiweeklyType = undefined;
    const weekNumber = schedule?.date_from ? moment(schedule.date_from).isoWeek() : 0;

    schedule?.rules.forEach(rule => {
      const { rrule } = rule;
      if (rrule.includes("FREQ=WEEKLY") && rrule.includes("INTERVAL=2")) {
        isBiweekly = true;
        if (["odd", "even"].includes(rule?.parity)) {
          setBiweeklyType(rule?.parity);
        } else if(weekNumber > 0) {
          newBiweeklyType = weekNumber % 2 === 0 ? "even" : "odd";
          setBiweeklyType(newBiweeklyType);
        }
      }
    });
    
    setIsBiweeklyRequired(isBiweekly);
    // scheduleEditForm.setFieldsValue({ biweeklyType: newBiweeklyType });

  }, [schedule, scheduleEditForm]);

  const getMaxDayCountRule = (rules) => {
    if (rules) {
      let maxRule = rrulestr(rules[0].rrule);

      rules?.forEach((rule) => {
        rule = rrulestr(rule.rrule);
        if (rule?.options?.byweekday?.length > maxRule?.options?.byweekday?.length) {
          maxRule = rule;
        }
      });

      return maxRule;
    }
  };

  return (
    <React.Fragment>
      <Modal
        key={schedule?.id}
        open={isOpen}
        title={t("schedules.editScheduleRules")}
        onOk={() => {
          scheduleEditForm.submit();
        }}
        // okButtonProps={{ loading: isSubmitting }}
        onCancel={() => {
          scheduleEditForm.resetFields();
          close();
        }}
      >
        <Form
          form={scheduleEditForm}
          onFinish={(values) => {
            let payload = {
              building_unit_id: values.building_unit_id,
              partner_id: values.partner_id,
              order_id: values.offer_id,
              date_from: values.date_from?.format("YYYY-MM-DD"),
              date_to: values.date_to?.format("YYYY-MM-DD"),
              // substitute_id: values.substitute_id,
              service_rrules: [...schedule.rules].map((item) => item.id),
              cleaning_days: values.days,
              biweekly_type: biweeklyType,
              service_ratio: Number(values.service_ratio),
            };

            coreApi
              .put("/schedules/mass-update", payload)
              .then((res) => {
                refreshScheduleList({ ...schedule, rules: res.data });
                close();
              })
              .catch((err) =>
                notification.error({
                  message:
                    err.response.data?.errors?.date_from ??
                    err.response.data?.errors?.date_to ??
                    err.response.data.message,
                })
              );
          }}
          onValuesChange={(_, allValues) => {
            if (allValues.frequency) {
              let days = allValues?.days?.map((day) => RRule[day]);

              let rrule = new RRule({
                freq: RRule[allValues.frequency],
                dtstart: allValues?.date_from?.toDate() || null,
                interval: allValues?.interval || null,
                byweekday: days || [],
              });

              scheduleEditForm.setFieldsValue({
                rrule: rrule.toString(),
              });
            }
          }}
        >
          <Row gutter={16}>
            <Form.Item hidden name={"offer_id"} initialValue={schedule?.offer_id}></Form.Item>
            <Form.Item hidden name={"building_unit_id"} initialValue={buildingUnitId}></Form.Item>
            <Col xs={12} md={12}>
              <RruleDaySelect
                rrule={getMaxDayCountRule(schedule?.rules)?.toString()}
                setSelectedDaysCount={(count) => {
                  // setSelectedDaysCount(count);
                }}
              />
            </Col>
            <Col xs={12} md={12}>
              {isBiweeklyRequired && <BiweeklyTypeSelect
                postChange={(value) => {
                  setBiweeklyType(value);
                }}
                hasFeedback
                biweeklyType={biweeklyType}
                required={isBiweeklyRequired}
              />}
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={"date_from"}
                initialValue={schedule?.date_from ? moment(schedule.date_from) : null}
                label={t("schedules.dateFrom")}
                required
              >
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().startOf("month").format("YYYY-MM-DD")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={"date_to"}
                initialValue={schedule?.date_to ? moment(schedule.date_to) : null}
                label={t("schedules.dateTo")}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().endOf("month").format("YYYY-MM-DD")
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <PartnerSelect name={"partner_id"} initialValue={schedule?.partner_id} label={t("schedules.partner")} />
            </Col>
            <Col>
              <Form.Item
                label={t("schedules.serviceRatio")}
                name={"service_ratio"}
                initialValue={schedule?.service_ratio}
              >
                <InputNumber max={0.99} min={0.0} step={0.01}></InputNumber>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ScheduleMassEditForm;
