import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const EmployeeSelect = ({
  label,
  name,
  initialValue,
  disabledOption,
  readOnly,
  required,
  validationMessage,
  size,
  onChange = null,
  placeholder = null,
  activeUsers = false,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    coreApi
      .get(activeUsers ? "/employees/all-active" : "/employees/all")
      .then((response) => {
        if (isMounted) {
          setEmployees(response.data);
        }
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      initialValue={initialValue}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        size={size}
        allowClear
        disabled={readOnly}
        bordered={!readOnly}
        loading={loading}
        showSearch={true}
        placeholder={placeholder ?? t("partners.selectPartner")}
        filterOption={(input, option) =>
          option?.children?.toString().toLowerCase().indexOf(input?.toString().toLowerCase()) >= 0
        }
        onChange={(e) => {
          onChange && onChange(employees.find((item) => item.id === e));
        }}
      >
        {employees.map((employee) => {
          const employeeName = `${employee.first_name} ${employee.last_name}`;
          return (
            <Select.Option
              key={employee.id}
              value={employee?.user?.id}
              disabled={disabledOption && employee?.user?.id === disabledOption}
            >
              {employee?.user?.deleted_at ? <s>{employeeName}</s> : employeeName}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default EmployeeSelect;
