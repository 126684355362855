import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CitiesSelector from "../Cities/CitiesSelector";

const gutter = [16, 24];

const PartnerLeadAddForm = ({ onFinishAction }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [addPartnerLeadForm] = Form.useForm();

  // const user = JSON.parse(localStorage.getItem("user"));

  const pasteHandler = (e) => {
    let formatedData = [];

    let data = e.clipboardData
      .getData("text/plain")
      .split(":")
      .join(",")
      .split("\n")
      .join()
      .split(",");

    data.forEach((item, index) => {
      if (index % 2 !== 0) formatedData.push(item);
    });

    if (formatedData.length < 3) return;

    let nameSplitted = formatedData[0].trim().split(" ");

    addPartnerLeadForm.setFieldsValue({
      first_name: nameSplitted[0],
      last_name: nameSplitted[1],
      email: formatedData[1].trim(),
      phone: formatedData[2].trim(),
    });
  };

  return (
    <React.Fragment>
      <Button
        size="large"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("partnerLeads.add")}
      </Button>
      <Modal
        title={t("partnerLeads.add")}
        visible={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        okText={t("common.add")}
        onOk={addPartnerLeadForm.submit}
      >
        <Form
          onPaste={(e) => pasteHandler(e)}
          form={addPartnerLeadForm}
          onFinish={async (values) => {
            await onFinishAction(values);
            setModalOpen(false);
          }}
          layout="vertical"
        >
          <Row gutter={gutter}>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label={t("common.firstName")}
                rules={[
                  {
                    required: true,
                    message: t("partnerLeads.validation.firstName"),
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label={t("common.lastName")}
                rules={[
                  {
                    required: true,
                    message: t("partnerLeads.validation.lastName"),
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={t("common.phone")}
                rules={[
                  {
                    required: true,
                    message: t("partnerLeads.validation.phone"),
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label={t("common.email")}
                rules={[
                  {
                    required: true,
                    message: t("partnerLeads.validation.email"),
                  },
                  {
                    type: "email",
                    message: t("common.emailValidation"),
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={24}>
              <Form.Item name="notes" label={t("common.notes")}>
                <Input.TextArea></Input.TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <CitiesSelector required={true}></CitiesSelector>
            </Col>
            <Col span={8}>
              <Form.Item
                name="do_not_contact"
                label={t("partnerLeads.doNotContact")}
                valuePropName="checked"
              >
                <Checkbox defaultChecked={false}></Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerLeadAddForm;
