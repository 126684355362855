import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Space, Tag, Checkbox } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Contact = ({ props, contact, updateContact, deleteContact, deleteContactRelation, tags }) => {
  const { t } = useTranslation();

  const [contactForm] = Form.useForm();
  const [isEditing, setEditing] = useState(false);
  const [deletingModalOpen, setDeletingModalOpen] = useState(false);

  return (
    <Form
      style={{ width: "100%" }}
      form={contactForm}
      layout="vertical"
      autoComplete="off"
      onFinish={(values) => {
        setEditing(false);
        updateContact(values);
      }}
    >
      <Row gutter={[16, 0]}>
        <Col span={4} style={{ justifyContent: "center" }}>
          {isEditing ? (
            <Space>
              <Form.Item hidden name={"contact_id"} initialValue={contact?.id}>
                <Input readOnly={!isEditing} bordered={isEditing}></Input>
              </Form.Item>
              <Form.Item name={"first_name"} initialValue={contact?.first_name}>
                <Input readOnly={!isEditing} bordered={isEditing}></Input>
              </Form.Item>
              <Form.Item name={"last_name"} initialValue={contact?.last_name}>
                <Input readOnly={!isEditing} bordered={isEditing}></Input>
              </Form.Item>
            </Space>
          ) : (
            <Link to={`/contacts/${contact?.id}`} style={{ fontWeight: 500 }}>
              <p
                style={{
                  width: "100%",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {!contact?.first_name && !contact?.last_name
                  ? t("common.empty")
                  : (contact?.first_name || "") + " " + (contact?.last_name || "")}
              </p>
            </Link>
          )}
        </Col>
        <Col span={4} style={{ justifyContent: "center" }}>
          <Form.Item
            name={"email"}
            initialValue={contact?.email}
            rules={[
              {
                type: "email",
                message: t("common.emailValidation"),
              },
            ]}
          >
            <Input readOnly={!isEditing} bordered={isEditing} style={{ textAlign: "center" }}></Input>
          </Form.Item>
        </Col>
        <Col span={4} style={{ justifyItems: "center" }}>
          <Form.Item name={"phone"} initialValue={contact?.phone}>
            <Input readOnly={!isEditing} bordered={isEditing} style={{ textAlign: "center" }}></Input>
          </Form.Item>
        </Col>
        <Col span={4} style={{ justifyContent: "center" }}>
          <Form.Item name={"company_name"} initialValue={contact?.company_name}>
            <Input readOnly={!isEditing} bordered={isEditing} style={{ textAlign: "center" }}></Input>
          </Form.Item>
        </Col>
        <Col span={6} style={{ justifyContent: "center" }}>
          {isEditing ? (
            <Row>
              <Form.Item
                name="tags"
                valuePropName="checked"
                initialValue={[...contact?.tags?.map((tag) => tag?.tag_id)]}
              >
                <Checkbox.Group style={{ width: "100%" }} defaultValue={[...contact?.tags?.map((tag) => tag?.tag_id)]}>
                  <Row>
                    {tags.map((tag) => {
                      return (
                        <Col key={tag?.id} span={6}>
                          <Checkbox value={tag?.id}>{t(`contacts.${tag?.key}`)}</Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Row>
          ) : (
            <div style={{ display: "flex", gap: "12px" }}>
              {contact?.tags?.map((tag) => {
                return (
                  <Tag color={t(`contacts.tagColors.${tag?.tag?.key}`)} style={{ textAlign: "center" }}>
                    {tag?.tag?.translation?.tag_name}
                  </Tag>
                );
              })}
            </div>
          )}
        </Col>
        <Col span={2} style={{ justifyContent: "center" }}>
          {isEditing ? (
            <Space>
              <Button icon={<CheckOutlined />} onClick={contactForm.submit}></Button>
              <Button
                danger
                icon={<CloseOutlined />}
                onClick={() => {
                  contactForm.resetFields();
                  setEditing(false);
                }}
              ></Button>
            </Space>
          ) : (
            <Space>
              <Button icon={<EditOutlined />} onClick={() => setEditing(true)}></Button>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  setDeletingModalOpen(true);
                }}
              ></Button>
            </Space>
          )}
        </Col>
      </Row>
      <Modal
        key={contact?.id}
        open={deletingModalOpen}
        title={t("contacts.delete.alertTitle")}
        onCancel={() => setDeletingModalOpen(false)}
        footer={[
          <Button onClick={() => setDeletingModalOpen(false)}>{t("contacts.storno")}</Button>,
          <Button
            type="danger"
            onClick={() =>
              Modal.confirm({
                title: t("common.lastWarning"),
                okText: t("common.delete"),
                okButtonProps: { type: "danger" },
                onOk: () => {
                  // delete relation
                  setDeletingModalOpen(false);
                  deleteContactRelation({
                    contact_id: contact?.id,
                  });
                },
              })
            }
          >
            {t("contacts.deleteRelation")}
          </Button>,
          <Button
            type="danger"
            onClick={() =>
              Modal.confirm({
                title: t("common.lastWarning"),
                okText: t("common.delete"),
                okButtonProps: { type: "danger" },
                onOk: () => {
                  // delete contact
                  setDeletingModalOpen(false);
                  deleteContact(contact);
                },
              })
            }
          >
            {t("contacts.deleteContact")}
          </Button>,
        ]}
      >
        {t("contacts.delete.alertMessage")}
      </Modal>
    </Form>
  );
};

export default Contact;
