import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, notification, Row, Col } from 'antd';
import CitiesSelector from '../Cities/CitiesSelector';
import BuildingSelect from './BuildingSelect';
import BuildingUnitSelect from './BuildingUnitSelect';
import PartnerSelect from '../Partners/PartnerSelect';
import ManagerSelect from '../ManagerSelect';
import { coreApi } from "../../api/calls";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const BuildingUnitSetupChangesAddForm = ({ onFormSubmit }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [statuses, setStatuses] = useState([{"id": 1,"name": t("buildingSetupChanges.statusInSolution") }]);
  const [actions, setActions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [statusesRes, actionsRes] = await Promise.all([
        coreApi.get("/building-unit-setup-changes/statuses"),
        coreApi.get("/building-unit-setup-changes/actions"),
      ]);

      setStatuses(statusesRes.data);
      setActions(actionsRes.data);
    } catch (error) {
      notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") });
    }
  };

  const handleCityChange = (value, option) => {
    const countryBranchId = option?.['data-country-branch-id'];
    form.setFieldsValue({ city_branch_id: value, country_branch_id: countryBranchId, building_id: null, partner_id: null, building_unit_ids: [] });
    setSelectedCity(value);
    setSelectedBuilding(null);
    setSelectedPartner(null);
  };

  const handleBuildingChange = (obj) => {
    form.setFieldsValue({ building_unit_ids: [], building_id: obj?.id });
    setSelectedBuilding(obj?.id);
  };

  const handlePartnerChange = (obj) => {
    form.setFieldsValue({ building_unit_ids: [], partner_id: obj?.id });
    setSelectedPartner(obj?.id);
  };

  const handleSubmit = async (values) => {
    if (!values.building_unit_ids || values.building_unit_ids.length === 0) {
      notification.error({ message: t("buildingSetupChanges.validation.requiredBuildingUnit") });
      return;
    }

    const { period, ...restValues } = values;
    const action_week_from = period ? period[0] : null;
    const action_week_to = period ? period[1] : null;

    const payload = {
      ...restValues,
      action_week_from,
      action_week_to,
    };

    coreApi
      .post("/building-unit-setup-changes", payload)
      .then(() => {
        notification.success({ message: t("buildingSetupChanges.messages.addedSuccessfully") });
        setSelectedCity(null);
        setSelectedBuilding(null);
        setSelectedPartner(null);
        form.resetFields();
        onFormSubmit();
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedAdd") });
      });
  };

  useEffect(() => {
    return () => {
      form.resetFields();
      setSelectedCity(null);
      setSelectedBuilding(null);
      setSelectedPartner(null);
    };
  }, [form]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{ status_id: 1 }}
    >
      <CitiesSelector
        onChange={handleCityChange}
        mode="single"
      />
      <Form.Item
        name="city_branch_id"
        hidden={true}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="country_branch_id"
        hidden={true}
      >
        <Input type="hidden" />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <BuildingSelect
            label={t("buildingSetupChanges.building")}
            name="building_id"
            validationMessage={t("buildingSetupChanges.validation.requiredBuilding")}
            onChange={handleBuildingChange}
            cityBranchId={selectedCity}
          />
        </Col>
        <Col span={12}>
          <PartnerSelect
            label={t("buildingSetupChanges.partners")}
            name="partner_id"
            onChange={handlePartnerChange}
          />
        </Col>
      </Row>

      {(selectedCity || selectedBuilding || selectedPartner) ? (
        <BuildingUnitSelect
          label={t("buildingSetupChanges.buildingUnit")}
          name="building_unit_ids"
          required
          validationMessage={t("buildingSetupChanges.validation.requiredBuildingUnit")}
          buildingId={selectedBuilding}
          partnerId={selectedPartner}
          cityBranchId={selectedCity}
          selectAllOnLoad={true}
          mode="multiple"
          form={form}
        />
      ) : (
        <Form.Item
          label={t("buildingSetupChanges.buildingUnit")}
          required
        >
          <Select disabled placeholder={t("buildingSetupChanges.selectBuildingUnit")} />
        </Form.Item>
      )}

      <Form.Item
        name="status_id"
        label={t("buildingSetupChanges.status")}
        rules={[{ required: true, message: t("buildingSetupChanges.validation.requiredStatus") }]}
      >
        <Select placeholder={t("buildingSetupChanges.selectStatus")}>
          {statuses.map(status => (
            <Option key={status.id} value={status.id}>{status.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="action_id"
        label={t("buildingSetupChanges.action")}
        rules={[{ required: true, message: t("buildingSetupChanges.validation.requiredAction") }]}
      >
        <Select placeholder={t("buildingSetupChanges.selectAction")}>
          {actions.map(action => {
            const translation = action.translations.find(t => t.language_ietf === i18n.language) || action.translations[0];
            return (
              <Option key={action.id} value={action.id}>
                {translation?.tag_name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <ManagerSelect
        label={t("buildingSetupChanges.manager")}
        validationMessage={t("buildingSetupChanges.validation.requiredManager")}
        required
        readOnly={false}
        showSearch={true}
      />
      <Form.Item
        label={t("common.selectedPeriod")}
        name="period"
        rules={[{ required: true, message: t("buildingSetupChanges.validation.requiredPeriod") }]}
      >
        <DatePicker.RangePicker
          placeholder={[t("buildingSetupChanges.selectStartDate"), t("buildingSetupChanges.selectEndDate")]}
          allowEmpty={[false, true]}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("common.save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BuildingUnitSetupChangesAddForm;
