import { CopyOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  message,
  Radio,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import { stringify } from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { getAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import ManagerSelect from "../components/ManagerSelect";
import PartnerAddForm from "../components/Partners/PartnerAddForm";
import PartnerStateSelector from "../components/Partners/PartnerStateSelector";
import PartnerLocationSelector from "../components/Partners/PartnerLocationSelector";
import IconTooltip from "../components/IconTooltip";

const PartnersIndex = (props) => {
  const prepareDataForClipboard = () => {
    let outString = "";
    selectedCells.forEach((item) => {
      outString += item.email + "\n";
    });
    return outString;
  };
  const { t } = useTranslation();

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope.areaScope;

  const [params, setParams] = useState(new URLSearchParams(props.location.search));
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const [isPartnerListLoading, setPartnerListLoading] = useState(false);

  const [selectedCells, setSelectedCells] = useState([]);

  const [filterForm] = Form.useForm();

  let defaultFilters = {
    date_from: params.get("date_from")
      ? moment(params.get("date_from"))
      : moment().subtract(1, "months").startOf("month"),
    date_to: params.get("date_to") ? moment(params.get("date_to")) : moment().subtract(1, "months").endOf("months"),
    is_active: params.get("is_active") ? params.get("is_active") : 2,
    active: params.get("active") === "true" ? true : false,
    active_no_active_partners: params.get("active_no_active_partners") === "true" ? true : false,
    active_no_building_partners: params.get("active_no_building_partners") === "true" ? true : false,
    noContractor: params.get("noContractor") === "true" ? true : false,
    manager_id: params.get("manager_id") ? Number(params.get("manager_id")) : "",
    page: params.get("page") ? Number(params.get("page")) : "",
    state: params.get("state") ?? null,
    preferred_location: params.get('preferred_location') ?? [],
    is_trainer: params.get("is_trainer") ? params.get("is_trainer") : null,
    product_type: params.get('preferred_location') ?? [],
  };

  const [filters, setFilters] = useState(defaultFilters);

  const history = useHistory();

  useEffect(() => {
    filterForm.resetFields();
  }, [filters, filterForm]);
  const [pagination, setPagination] = useState();

  const [partnersList, setPartnersList] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const allDataForClipboard = () => {
    let outString = "";
    partnersList.forEach((item) => {
      if (item.email && item.email !== '---') outString += item.email + "\n";
    });
    return outString;
  };

  useEffect(() => {
    setPartnerListLoading(true);

    axios
      .all([
        coreApi.get("/partners", {
          params: {
            ...filters,
            date_to: filters.date_to.format("YYYY-MM-DD"),
            date_from: filters.date_from.format("YYYY-MM-DD"),
            city_branch_id: selectedCity,
          },
        }),
      ])
      .then(
        axios.spread((partners, stats) => {
          let { data, ...pagination } = partners.data;
          setPartnersList(data);
          setPagination({ ...pagination });

          history.push("/partners?" + stringify(partners.config.params));
        })
      )
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPartnerListLoading(false);
      });
  }, [filters, selectedCity, history, isFormSubmitting]);

  const addToPartnerList = (newPartner) => {
    setPartnersList([newPartner, ...newPartner?.workers, ...partnersList]);
  };

  const columns = [
    {
      title: t("partners.companyName"),
      key: "name",
      width: "20%",
      sorter: (a, b) =>
        a?.company_name ? a?.company_name.localeCompare(b?.company_name) : a?.last_name.localeCompare(b?.last_name),
      render: (partner) => {
        if (partner.contract_ended === null || !partner?.contract_ended) {
          return (
            <>
              <Tooltip
                placement="topLeft"
                title={partner?.company_name ? partner?.company_name : partner?.first_name + " " + partner?.last_name}
              >
                <Link to={`/partners/${partner?.id}`} style={{ fontWeight: 500 }}>
                  {partner?.company_name ? partner?.company_name : partner?.first_name + " " + partner?.last_name}
                </Link>
              </Tooltip>
              {partner.label !== undefined && partner.label !== null && partner.label !== "" && (() => {
                const label = partner.label > 0 ? 'new' : 'newEmpty';
                return (
                  <span className={'table-label ' + (label === 'new' ? 'green' : '')}>
                    {t("partners.label." + label)}
                  </span>
                  );
              })()}
              {partner && partner?.notes && (
                <IconTooltip message={partner?.notes} />
              )}
            </>
          );
        } else if (moment(partner.contract_ended) < moment()) {
          return (
            <Link to={`/partners/${partner?.id}`}>
              <Typography.Text delete style={{ color: "inherit" }}>
                {partner?.company_name ? partner?.company_name : partner?.first_name + " " + partner?.last_name}
                {partner && partner?.notes && (
                  <IconTooltip message={partner?.notes} />
                )}
              </Typography.Text>
            </Link>
          );
        }
      },
    },
    {
      title: t("clientLeads.unitsCount"),
      key: "buildings_count",
      width: "10%",
      sorter: (a, b) => a?.buildings_count.localeCompare(b?.buildings_count),
      render: (partner) => (
        <span>
          {partner.buildings_count ? partner.buildings_count : '0'}
        </span>
      ),
    },
    {
      title: t("common.partnerState"),
      dataIndex: "state",
      key: "state",
      width: "10%",
      sorter: (a, b) => a.state && b.state && a?.state.localeCompare(b?.state),
      render: (state) => (
        <span>
          {state ? t("partners.states." + state) : ''}
        </span>
      ),
    },
    {
      title: (
        <Space>
          <Tooltip title={t("common.howToCopy")}>{t("contacts.email")}</Tooltip>
          <Tooltip title={t("common.copy")}>
            <Button
              style={{ border: "0px", background: "none" }}
              icon={<CopyOutlined />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (selectedCells.length > 0) {
                  try {
                    navigator.clipboard.writeText(prepareDataForClipboard());
                    message.success(t("common.copySuccess"), 1);
                  } catch (err) {
                    message.error(t("common.copyError"));
                  }
                  setTimeout(() => setSelectedCells(() => []), 250);
                } else {
                  try {
                    navigator.clipboard.writeText(allDataForClipboard());
                    message.success(t("common.copySuccess"), 1);
                  } catch (err) {
                    message.error(t("common.copyError"));
                  }
                }
              }}
            ></Button>
          </Tooltip>
        </Space>
      ),
      width: "20%",
      className: "table-cell",
      sorter: (a, b) => a?.email.localeCompare(b?.email),
      render: (rowData) => {
        return (
          <span
            style={
              selectedCells.includes(rowData)
                ? {
                    height: "100%",
                    padding: "8px",
                    background: "rgba(105, 192, 255, 0.6)",
                    borderRadius: "5px",
                  }
                : { height: "100%", padding: "8px" }
            }
          >
            {rowData.email}
          </span>
        );
      },
      onCell: (record, _) => {
        return {
          onClick: (event) => {
            if (event.shiftKey) {
              if (selectedCells.includes(record)) {
                setSelectedCells(() => [...selectedCells.filter((item) => item !== record)]);
              } else {
                setSelectedCells(() => [...selectedCells, record]);
              }
            }
          },
        };
      },
    },
    {
      title: t("common.phone"),
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone && b.phone && a?.phone.localeCompare(b?.phone),
      render: (phone) => (
        <a href={"tel:" + phone} style={{ borderBottom: "1px dotted" }}>
          {phone}
        </a>
      ),
    },
    {
      title: t("common.partnerLocation"),
      dataIndex: "preferred_location",
      key: "preferred_location",
      width: "10%",
      sorter: (a, b) => a.preferred_location && b.preferred_location && a?.preferred_location.localeCompare(b?.preferred_location),
      render: (preferred_location) => {
        if (preferred_location) {
          const locationsArray = JSON.parse(preferred_location);
          return (
            <span>
          {locationsArray.map((item, index) => (
            <span key={index} style={{ display: 'inline-block', marginRight: '5px' }}>
              {item}{index < locationsArray.length - 1 ? ',' : ''}
            </span>
          ))}
        </span>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: t("expansion.managerName"),
      sorter: (a, b) =>
        a.user?.employee?.last_name &&
        b.user?.employee?.last_name &&
        a?.user?.employee?.last_name.localeCompare(b?.user?.employee?.last_name),
      render: (rowData) => {
        return rowData?.user?.employee?.employee_id ? (
          <Link to={"/employees/" + rowData?.user?.employee?.employee_id} style={{ borderBottom: "1px dotted" }}>
            {rowData?.user?.employee?.first_name + " " + rowData?.user?.employee?.last_name}
          </Link>
        ) : (
          <p>{t("common.empty")}</p>
        );
      },
    },
    // {
    //   title: t("common.actions"),
    //   key: "options",
    //   render: (partner) => (
    //     <Popconfirm
    //       title={t("common.confirmDelete")}
    //       onConfirm={() => {
    //         coreApi
    //           .delete("/partners/" + partner?.id)
    //           .then((response) => {
    //             if (response.status === 204) {
    //               removeFromPartnerList(partner?.id);
    //               notification.success({
    //                 message: t("common.recordDeleted"),
    //               });
    //             } else {
    //               notification.warning({
    //                 message: t("common.deletionFailed"),
    //               });
    //             }
    //           })
    //           .catch((error) => {
    //             notification.warning({
    //               message: t("common.deletionFailed"),
    //             });
    //           });
    //       }}
    //       onCancel={() => {}}
    //       okText={t("common.delete")}
    //       cancelText={t("common.cancel")}
    //     >
    //       <Button size="small" danger>
    //         {t("common.delete")}
    //       </Button>
    //     </Popconfirm>
    //   ),
    // },
  ];

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Row>
          <Col span={24}>
            <Card>
              <Form
                layout={"inline"}
                form={filterForm}
                className="filterForm"
                style={{ display: "flex", gap: "12px", paddingBottom: "20px" }}
              >
                <Row>
                  <Col style={{ display: "flex" }}>
                    <ManagerSelect
                      sendOnchange={(id) => {
                        setFilters({
                          ...filters,
                          manager_id: id,
                        });
                      }}
                      showSearch={true}
                      clearable={true}
                      readOnly={false}
                      label={t("employees.employee")}
                      initialValue={filters.manager_id}
                    ></ManagerSelect>
                    <PartnerStateSelector
                      required={false}
                      initialValue={filters.state}
                      readOnly={false}
                      clearable={true}
                      sendOnchange={(val) => {
                        setFilters({
                          ...filters,
                          state: val,
                        });
                      }}
                    />
                    <PartnerLocationSelector
                      required={false}
                      initialValue={filters.preferred_location}
                      readOnly={false}
                      clearable={true}
                      sendOnchange={(val) => {
                        setFilters({ ...filters, preferred_location: val });
                      }}
                    />
                  </Col>

                  <Space style={{ marginTop: "12px" }}>
                    <Form.Item name={"is_active"}>
                      <Radio.Group
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            is_active: e.target.value,
                          });
                        }}
                        value={filters?.is_active}
                        defaultValue={filters?.is_active}
                      >
                        <Radio value={2}>{t("partners.activePartners")}</Radio>
                        <Radio value={3}>{t("partners.inactivePartners")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {/* Filters reset button */}
                    <Form.Item>
                      <Link
                        onClick={() => {
                          setParams(new URLSearchParams(null));
                          setFilters(defaultFilters);
                          filterForm.resetFields();
                        }}
                      >
                        {t("common.resetFilters")}
                      </Link>
                    </Form.Item>
                    <Form.Item
                      name={"is_trainer"}
                      valuePropName={filters.is_trainer ? "checked" : "unchecked"}
                      initialValue={filters.is_trainer}
                    >
                      <Checkbox
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            is_trainer: e.target.checked,
                          });
                        }}
                      >
                        {t("partners.trainerPartners")}
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <label style={{ marginRight: "15px"}}>{t("partners.productTypeLabel")}</label>
                      {Object.entries({
                        apart: t("partners.productTypes.apartment"),
                        office: t("partners.productTypes.office")
                      }).map(([name, label]) => (
                        <Checkbox
                          key={name}
                          value={name}
                          checked={filters.product_type.includes(name)}
                          onChange={(e) => {
                            const newProductTypes = e.target.checked
                              ? [...filters.product_type, name]
                              : filters.product_type.filter((type) => type !== name);

                            setFilters({
                              ...filters,
                              product_type: newProductTypes,
                            });
                          }}
                        >
                          {label}
                        </Checkbox>
                      ))}
                    </Form.Item>
                  </Space>
                </Row>
              </Form>

              {/*
                    <Col span={8}>
                      <Button
                        disabled={selectedRowKeys.length > 0 ? false : true}
                        onClick={() => {
                          coreApi
                            .post("/payments/partners", {
                              date_from: filters.date_from,
                              date_to: filters.date_to,
                              partners: selectedRowKeys.map((id) => ({
                                partner_id: id,
                              })),
                            })
                            .then((res) => {
                              notification.success({
                                message: res.data.message,
                              });
                              fileDownload(
                                res.data.xml,
                                "partners-payments-" +
                                  moment()?.format("YYYYMMDD") +
                                  "-" +
                                  moment().hour() +
                                  moment().minute() +
                                  moment().second() +
                                  ".xml"
                              );
                            })
                            .catch((err) =>
                              notification.error({
                                message: err.response.data.message,
                              })
                            );
                        }}
                      >
                        {t("finance.moneyReturn.generateXML")}
                      </Button>
                    </Col> */}

              <Table
                size="small"
                loading={isPartnerListLoading}
                rowKey={"id"}
                dataSource={partnersList}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (e) => {
                    setSelectedRowKeys(e);
                  },
                  type: "checkbox",
                }}
                columns={columns}
                pagination={{
                  current: pagination?.current_page,
                  pageSize: pagination?.per_page,
                  total: pagination?.total,
                  page: pagination?.current_page,
                  showSizeChanger: true,
                  onChange: (e, pageSize) => {
                    setFilters({ ...filters, page: e, perPage: pageSize });
                  },
                  showTotal: (total, range) => (
                    <p>
                      {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                      {" " + t("common.paginationPartTwo") + " "}
                      <strong>{total}</strong> {t("common.paginationPartThree")}
                    </p>
                  ),
                }}
              ></Table>
            </Card>
          </Col>
        </Row>
        <Col span={24}>
          <PartnerAddForm
            addToPartnersList={(partner) => {
              addToPartnerList(partner);
            }}
            isFormSubmitting={isFormSubmitting}
            setFormSubmitting={setFormSubmitting}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PartnersIndex;
