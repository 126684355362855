import { notification, Space, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";
import MoneyReturnPane from "../Buildings/MoneyReturnPane";
import MoneyReturnModal from "../Clients/MoneyReturnModal";
import IconTooltip from "../IconTooltip";

/**
 * @component
 * @alias BalancePane
 * @prop {object} client -  client with all it's details
 */
const BalancePane = ({ client }) => {
  const { t } = useTranslation();

  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState({});

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setDataLoading(true);
      coreApi
        .get("invoices/partner-client", {
          params: {
            client_id: client?.id,
            page: filters?.page,
          },
        })
        .then((res) => {
          let { data, ...pagination } = res.data;

          setData(data);
          setPagination({ ...pagination });
        })

        .catch((err) => notification.error({ message: err?.response?.data?.message }))
        .finally(() => setDataLoading(false));
    }

    return () => {
      mounted = false;
    };
  }, [client, filters, loading]);

  // defining table columns
  const balanceTableColumns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      width: 100,
      render: (rowData) => {
        return moment(rowData).format("YYYY") + "/" + moment(rowData).format("MM");
      },
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      width: 120,
      render: (invoice) => {
        return (
          <Link to={"/invoices/" + invoice?.id} style={{ fontWeight: 500 }}>
            {invoice?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.invoices"),
      key: "invoices",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "total_amount",
          width: 150,
          render: (rowData) => {
            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(rowData.total_amount);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "partnerClientInvoices",
          width: 150,
          render: (rowData) => {
            let paidTransaction = () => {
              let sum = 0;

              rowData?.transactions?.forEach((transaction) => {
                sum += parseFloat(transaction?.pivot?.amount);
              });

              return sum;
            };

            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(paidTransaction());
          },
        },
      ],
    },
    {
      title: t("finance.invoices.correctiveDocuments"),
      key: "correctiveDocuments",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "correctiveDocuments",
          width: 150,
          render: (rowData) => {
            let correctiveDocuments = () => {
              let sum = 0;

              if (rowData?.corrective_documents) {
                rowData?.corrective_documents.forEach((correctiveDocument) => {
                  sum += parseFloat(correctiveDocument?.total_amount || 0);
                });
              }

              return sum;
            };

            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(correctiveDocuments() || 0);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "correctiveDocumentsTransactions",
          width: 150,
          render: (rowData) => {
            let paidCorrectiveTransaction = () => {
              let sum = 0;

              if (rowData?.corrective_documents) {
                rowData?.corrective_documents.forEach((correctiveDocument) => {
                  correctiveDocument?.transactions?.forEach((trans) => {
                    sum += parseFloat(trans?.pivot?.amount || 0);
                  });
                });
              }

              return sum;
            };

            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(0 - paidCorrectiveTransaction() || 0);
          },
        },
      ],
    },
    {
      title: t("finance.lineItems.totalAmount"),
      key: "totalInvoices",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "totalToBePaid",
          width: 150,
          render: (rowData) => {
            let invoice_total = parseFloat(rowData?.total_amount);

            let correctiveDocuments = () => {
              let sum = 0;

              if (rowData?.corrective_documents) {
                rowData?.corrective_documents.forEach((correctiveDocument) => {
                  sum += parseFloat(correctiveDocument?.total_amount || 0);
                });
              }

              return sum;
            };

            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(invoice_total + correctiveDocuments() || 0);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "totalTransactions",
          width: 150,
          render: (rowData) => {
            let paidTransaction = () => {
              let sum = 0;

              rowData?.transactions?.forEach((transaction) => {
                sum += parseFloat(transaction?.pivot?.amount);
              });

              return sum;
            };

            let paidCorrectiveTransaction = () => {
              let sum = 0;

              if (rowData?.corrective_documents) {
                rowData?.corrective_documents.forEach((correctiveDocument) => {
                  correctiveDocument?.transactions?.forEach((trans) => {
                    sum += parseFloat(trans?.pivot?.amount || 0);
                  });
                });
              }

              return sum;
            };

            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(paidTransaction() - paidCorrectiveTransaction() || 0);
          },
        },
      ],
    },
    {
      title: t("finance.balances.difference"),
      key: "account_name_from",
      width: 150,
      render: (rowData) => {
        let invoice_total = parseFloat(rowData?.total_amount);
        let correctiveDocuments = () => {
          let sum = 0;

          if (rowData?.corrective_documents) {
            rowData?.corrective_documents.forEach((correctiveDocument) => {
              sum += parseFloat(correctiveDocument?.total_amount || 0);
            });
          }

          return sum;
        };

        let paidTransaction = () => {
          let sum = 0;

          rowData?.transactions?.forEach((trans) => {
            sum += parseFloat(trans?.pivot?.amount || 0);
          });

          if (rowData?.corrective_documents) {
            rowData?.corrective_documents.forEach((correctiveDocument) => {
              correctiveDocument?.transactions?.forEach((trans) => {
                sum += parseFloat(trans?.pivot?.amount || 0);
              });
            });
          }

          return sum;
        };

        let total = invoice_total + correctiveDocuments() - paidTransaction();
        return (
          <>
            <span>
              {new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: rowData.currency_iso_4217 || "CZK",
              }).format(total)}
              {total !== 0 ? (
                total > 0 ? (
                  <IconTooltip message={t("finance.balances.unpaid")} />
                ) : (
                  <IconTooltip message={t("finance.balances.overpaid")} />
                )
              ) : (
                <IconTooltip message={t("finance.balances.paid")} />
              )}
            </span>
          </>
        );
      },
    },
    {
      title: t("common.action"),
      key: "invoice_number",
      width: 225,
      render: (invoice, _, index) => {
        let invoice_total = parseFloat(invoice?.total_amount);

        let correctiveDocuments = () => {
          let sum = 0;

          if (invoice?.corrective_documents) {
            invoice?.corrective_documents.forEach((correctiveDocument) => {
              sum += parseFloat(correctiveDocument?.total_amount || 0);
            });
          }

          return sum;
        };

        let paidTransaction = () => {
          let sum = 0;

          invoice?.transactions?.forEach((trans) => {
            sum += parseFloat(trans?.pivot?.amount || 0);
          });

          if (invoice?.corrective_documents) {
            invoice?.corrective_documents.forEach((correctiveDocument) => {
              correctiveDocument?.transactions?.forEach((trans) => {
                sum += parseFloat(trans?.pivot?.amount || 0);
              });
            });
          }

          return sum;
        };

        let total = invoice_total + correctiveDocuments() - paidTransaction();
        const dateIssued = invoice.date_issued;

        return (
          <Space>
            <MoneyReturnPane
              idClient={invoice?.client_id}
              buildingId={invoice?.building_id}
              total={total}
              invoice={invoice}
              client={client}
            />

            {total < 0 &&
              (!(invoice?.corrective_document?.sent_at || invoice?.sent_at) ? (
                <MoneyReturnModal
                  invoice={invoice}
                  client={client}
                  total={total}
                  loading={loading}
                  setLoading={setLoading}
                  dateIssued={dateIssued}
                ></MoneyReturnModal>
              ) : (
                <Tag color="blue" style={{ fontSize: "10px" }}>
                  {t("finance.transactions.sentAt")}:{" "}
                  {moment(invoice?.corrective_document?.sent_at || invoice?.sent_at)?.format("D. M. Y HH:mm")}
                </Tag>
              ))}
          </Space>
        );
      },
    },
  ];

  return (
    <React.Fragment key={"BalancePane"}>
      <div>
        <Table
          style={{ overflowX: "auto", width: "fit-content" }}
          size="small"
          loading={dataLoading}
          bordered
          columns={balanceTableColumns}
          dataSource={[...data]}
          rowKey={"id"}
          pagination={{
            current: pagination?.current_page,
            pageSize: pagination?.per_page,
            total: pagination?.total,
            page: pagination?.current_page,
            showSizeChanger: false,
            onChange: (e) => {
              setFilters({ ...filters, page: e });
            },
          }}
        ></Table>
      </div>
    </React.Fragment>
  );
};

export default BalancePane;
